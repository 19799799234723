import Marquee from "react-fast-marquee";
import NextImage from "next/image";
import FeaturedSection from "./FeaturedSection";

import BloombergMarquee from "public/landing-marquee-featured-in/bloomberg.png";
import BusinessInsiderMarquee from "public/landing-marquee-featured-in/business-insider.png";
import DallasMorningNewsMarquee from "public/landing-marquee-featured-in/dallas-morning-news.png";
import DeloitteMarquee from "public/landing-marquee-featured-in/deloitte.png";
import HoustonChronicleMarquee from "public/landing-marquee-featured-in/houston-chronicle.png";
import TechCrunchMarquee from "public/landing-marquee-featured-in/tech-crunch.png";
import USATodayMarquee from "public/landing-marquee-featured-in/usa-today.png";
import WSJMarquee from "public/landing-marquee-featured-in/wall-street-journal.png";
import YahooFinanceMarquee from "public/landing-marquee-featured-in/yahoo-finance.png";

const BloombergMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={BloombergMarquee}
				alt="Bloomberg"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const BusinessInsiderMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={BusinessInsiderMarquee}
				alt="Business Insider"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const DallasMorningNewsMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={DallasMorningNewsMarquee}
				alt="Dallas Morning News"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const DeloitteMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={DeloitteMarquee}
				alt="Deloitte"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const HoustonChronicleMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={HoustonChronicleMarquee}
				alt="Houston Chronicle"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const TechCrunchMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={TechCrunchMarquee}
				alt="Tech Crunch"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const USATodayMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={USATodayMarquee}
				alt="USA Today"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const WSJMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={WSJMarquee}
				alt="Wall Street Journal"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

const YahooFinanceMarqueeFeaturedImg = () => {
	return (
		<div className="marquee-featured-logos-img">
			<NextImage
				priority={true}
				quality={100}
				src={YahooFinanceMarquee}
				alt="Yahoo Finance"
				style={{
					maxWidth: "100%",
					height: "auto",
				}}
			/>
		</div>
	);
};

export const MarqueeFeaturedSection = ({ title }: { title?: string }) => {
	return (
		<div id="landing-marquee-section">
			<p className="bold marquee-featured-in">
				{title ? title : "Featured in"}
			</p>
			<Marquee speed={25}>
				<BloombergMarqueeFeaturedImg />
				<BusinessInsiderMarqueeFeaturedImg />
				<DeloitteMarqueeFeaturedImg />
				<TechCrunchMarqueeFeaturedImg />
				<USATodayMarqueeFeaturedImg />
				<WSJMarqueeFeaturedImg />
				<YahooFinanceMarqueeFeaturedImg />
				<DallasMorningNewsMarqueeFeaturedImg />
				<HoustonChronicleMarqueeFeaturedImg />
				{/* duplicate content for wide screens */}
				<BloombergMarqueeFeaturedImg />
				<BusinessInsiderMarqueeFeaturedImg />
				<DeloitteMarqueeFeaturedImg />
				<TechCrunchMarqueeFeaturedImg />
				<USATodayMarqueeFeaturedImg />
				<WSJMarqueeFeaturedImg />
				<YahooFinanceMarqueeFeaturedImg />
				<DallasMorningNewsMarqueeFeaturedImg />
				<HoustonChronicleMarqueeFeaturedImg />
			</Marquee>
		</div>
	);
};
